import React from "react"
import "./header.css"
import { GiHamburgerMenu } from "react-icons/gi"

const Header = () => {
    return (
        <div className="navbar">
            <GiHamburgerMenu className="hamburger" />
        </div>
    )
}

export default Header
